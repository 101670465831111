var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-add-info-wrap__btn" }, [
    _c(
      "button",
      { staticClass: "btn btn_small btn_green", on: { click: _vm.refund } },
      [
        _c("svg-icon", {
          staticClass: "m-i-end-1ch ic-white",
          attrs: { name: "ic-repeat", width: "9", height: "10" },
        }),
        _c("span", { domProps: { textContent: _vm._s(_vm.$t("Refund")) } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }